import React, {useEffect, useState} from 'react';
import Spacer from 'src/components/graphical/spacer';
import PropTypes from 'prop-types';
import MoreAboutUs from 'src/components/functional/more-about-us-section';
import Page from 'src/components/functional/page';
import {useLocation} from '@reach/router';
import {usePageContent} from 'src/functionality/content-provider';
import useJobPositions from 'src/functionality/job-position-provider';
import ContentBlock from 'src/components/functional/content-block';
import ArbiasButton from 'src/components/functional/arbias-button';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import {H3, H5, H6} from 'src/components/functional/headings';
import FeaturedHeroSection
  from 'src/components/functional/hero-section-featured';
import HeroImage from 'images/about/people/working-for-arbias.jpg';
import perksImage from 'images/perks.jpg';
import diversityImage from 'images/art.jpg';

import {theme} from 'src/styles/theme';
import {breakpoints} from 'src/styles/breakpoints';
import Strip from 'src/components/functional/strip';
import {useFeatureFlags} from 'src/functionality/flags-provider';

const PerksSection = styled.section``;
const JoinOurTeamSection = styled.section``;
const DiversityAndInclusionSection = styled.section``;

const Job = styled.div`
  border-top: 1px solid ${theme.muted};
  border-bottom: 1px solid ${theme.muted};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;

const JobDescription = styled.div``;

const ViewJob = styled.div``;

const JobBody = styled.p`
  border-left: 1px solid ${theme.lightGray};
  @media ${breakpoints.sm}{
    display: block;
    & > div{
      text-align: center
    }
  }
  display: flex;
  align-items: center;
`;

const JobPopup = styled.div`
  box-shadow: 0px 10px 27px 17px rgba(0,0,0,0.125);
  visibility: ${(props) => props.visible ? 'visible' : 'hidden'};
  width: min(95vw, 50em);
  height: 90vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: ${theme.white};
  padding: 1.5em;
  padding-top: 0;
  z-index: 1500;
  overflow-y: scroll;
  
  & .close:hover{
    cursor: pointer;
    color: ${theme.danger}
  }
  & .close{
    font-size: 3rem;
    color: ${theme.muted};
    line-height: 1.5em;
  }
  & .close-header{
    display: flex;
    justify-content: end;
    padding: 0.25em 0;
  }
`;

const Quote = styled.p`
  color: ${theme.primary};
  font-style: italic;
  text-align: center;
  font-size: 1.8em;
`;

const JobPopupFocusBG = styled.div`  
  position: fixed;
  background-color: #00000040;
  visibility: ${(props) => props.visible ? 'visible' : 'hidden'};
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 1010;
`;


const WorkingAtArbiasPage = ({pageContext}) => {
  const location = useLocation();
  const content = usePageContent(location.pathname, pageContext.content);
  const jobs = useJobPositions();
  const [selectedJob, setSelectedJob] = useState();
  const flags = useFeatureFlags();

  useEffect(() => {
    if (jobs && !selectedJob) {
      // setSelectedJob(jobs[window.location.hash.substring(1)[0]]);
      setSelectedJob(jobs.filter((job) =>
        job.id === window.location.hash.substring(1))[0]);
    }
  }, [selectedJob, jobs]);

  return (
    <Page white={true}>
      {selectedJob &&
      <JobPopup visible={selectedJob !== null}>
        <div className="close-header">
          <span className="close" onClick={() =>{
            setSelectedJob(); // deselect job
            history.pushState('', document.title, // Remove hash from url
                window.location.pathname + window.location.search);
          }}>
              &times;
          </span>
        </div>
        <ContentBlock
          header={(props) => <H3 {...props}/>}
          content={{X: {
            title: selectedJob.positionName,
            // eslint-disable-next-line max-len
            body: `*${selectedJob.title}*\n\n **${selectedJob.excerpt}** \n\n${selectedJob.description}`,
            tag: 'X',
          }}}
          tag='X'
        />
      </JobPopup>
      }
      <JobPopupFocusBG visible={selectedJob}/>
      <FeaturedHeroSection
        bgImage={HeroImage}
        breadcrumbs={true}
        content={content}
        tag="WA01"
      />
      <Strip padding="4vh">
        <PerksSection>
          <Container>
            <Row>
              <Col sm={12} md={6} xl={8}>
                <ContentBlock
                  header={H3}
                  content={content}
                  tag="WA02"
                />
              </Col>
              <Col sm={12} md={6} xl={4}>
                <img src={perksImage} alt="TODO:"/>
              </Col>
            </Row>
          </Container>
        </PerksSection>
      </Strip>
      <Strip bg={theme.bodyBg} padding="4vh">
        <DiversityAndInclusionSection>
          <Container>
            <Row>
              <Col sm={12} md={6} xl={8}>
                <ContentBlock
                  header={(props) => <H3 {...props}/>}
                  content={content}
                  tag="WA04"
                />
              </Col>
              <Col sm={12} md={6} xl={4}>
                <img src={diversityImage} alt="TODO:"/>
              </Col>
            </Row>

          </Container>
        </DiversityAndInclusionSection>
      </Strip>
      <Strip padding="4vh">
        <JoinOurTeamSection>
          <Container>
            <ContentBlock
              header={(props) => <H3 {...props}/>}
              content={content}
              tag="WA03"
              linkText="Enquire Today &gt;"
              link={
                (props) => <ArbiasButton to="/about/#contact" {...props}/>
              }
            />
            <Quote>
              &quot;Opportunities don&apos;t happen. You create them.&quot;
              <br/>
              — Chris Grosser
            </Quote>
            <Spacer height={4} />
            {flags.AboutPeopleWorkingAtArbias_EnableJobAds &&
              jobs?.map((job, key) => {
                return (
                  <Job key={key}>
                    <JobDescription>
                      <H5 noUnderline={true}>{job.positionName}</H5>
                      <H6 className="text-primary" noUnderline={true}>
                        {job.title}
                      </H6>
                      <JobBody>{job.excerpt}</JobBody>
                    </JobDescription>
                    <ViewJob>
                      <ArbiasButton
                        href={'#'+job.id}
                        onClick={() => setSelectedJob(key)}
                      >
                    &gt;
                      </ArbiasButton>
                    </ViewJob>
                  </Job>
                );
              })}
          </Container>
        </JoinOurTeamSection>
      </Strip>
      <MoreAboutUs />
    </Page>
  );
};
WorkingAtArbiasPage.propTypes = {
  pageContext:
  PropTypes.shape({
    content: PropTypes.object,
  }),
};

export default WorkingAtArbiasPage;


