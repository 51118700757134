import {useEffect, useState} from 'react';
import {API} from 'aws-amplify';
import {listEnabledJobPositions} from 'src/graphql/content';

/**
 * Returns all active jobpositions
 * @return {object} an object with multiple jobpositions
 */
export default function useJobPositions() {
  const [jobPositions, setJobPositions] = useState();

  useEffect(() => {
    if (!jobPositions) {
      (async () => {
        const data = await API.graphql({
          query: listEnabledJobPositions,
        });
        // Set positions and sort by updated at

        setJobPositions(data.data.listJobPositions.items
            .sort((a, b) => (a.updatedAt > b.updatedAt) ? 1 : -1));
      })();
    }
  });
  return (jobPositions);
}
